import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { showAmountWithIva } from 'src/app/core/extensions/calcs/iva';
import { AddressData } from 'src/app/core/models/users/users-data';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrl: './operation-detail.component.scss',
})
export class OperationDetailComponent {
  operation!: any;
  showIva!: boolean;
  //Datos estáticos:
  iva = 0.21;
  totalFinancingAmount = 60000;
  monthlyPayment = 1648.56;
  productPrice = 40000;
  customerDetails?: AddressData;
  shippingAddress?: AddressData;
  comisionApertura!: number;
  carencia!: number;
  entrada!: number;
  valorFinanciado!: number;
  plazo!: number;
  ultimoPago!: number;
  showAmountWithIva = showAmountWithIva
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.operation = history.state.operation;
    if(!this.operation){
      this.router.navigate(['/'])
    }
    const operationNumber = this.route.snapshot.paramMap.get('operationNumber');
    this.calcular();
    console.log(this.operation);
    if (this.operation?.customer?.identifier) {
      this.userService.getUserByIdentifier(this.operation.customer.identifier).pipe(
        tap((customerData) => {
          this.customerDetails = customerData.address;
          this.shippingAddress = customerData.shippingAddress;          
        }),
        catchError((error) => {
          console.error('Error al obtener los datos del cliente', error);
          return of (null)
        })
      ).subscribe();
    }
  }
  getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'aproved':
        return 'status-aprobado';
      case 'denied':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      case 'completed':
        return 'status-completado';
      case 'signed':
        return 'status-firmado';
      default:
        return '';
    }
  }

  translateState(state: string): string {
    switch (state.toLowerCase()) {
      case 'aproved':
        return 'Aprobado';
      case 'denied':
        return 'Denegado';
      case 'pending':
        return 'Pendiente';
      case 'completed':
        return 'Completado';
      case 'signed':
        return 'Firmado';
      default:
        return state;
    }
  }

  formatOperationId(operationId: number): string {
    return operationId.toString().padStart(6, '0');
  }

  toggleIVA() {
    this.showIva = !this.showIva;
  }

  calcular() {
    this.entrada = this.operation.downPayment;
    this.plazo = this.operation.monthsTerm;
    this.carencia = this.operation.gracePeriod;
    this.monthlyPayment = this.operation.installments[this.carencia].netPayment;
    this.totalFinancingAmount = this.operation.totalAmount;
    this.valorFinanciado = this.operation.financedCapital;
    this.comisionApertura = this.totalFinancingAmount * 0.01;
    const installments = this.operation.installments;
    this.ultimoPago = installments[installments.length - 1].netPayment;
  }
}
