import { FinancingService } from 'src/app/core/services/financing.service';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OperationService } from 'src/app/core/services/operation.service';
import { OperationData } from 'src/app/core/models/operations/operation-data';
import { PageEvent } from '@angular/material/paginator';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-operations-list',
  templateUrl: './operations-list.component.html',
  styleUrl: './operations-list.component.scss',
})
export class OperationsListComponent {
  public showFilters: boolean = false;
  length!: number; // length total de financiaciones
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent!: PageEvent;
  loader: boolean = false
  public offset: number = 10;

  public displayedColumns = [
    'operationNumber',
    'operationDate',
    'vatNumber',
    'companyName',
    'type',
    'amount',
    'status',
  ];
  public statusList: string[] = [
    'Inicial',
    'Aprobada',
    'Cancelada',
    'En Estudio',
  ];
  public operations: OperationData[] = [];

  constructor(
    private operationService: OperationService,
    private router: Router,
    private financingService: FinancingService
  ) { }

  ngOnInit(): void {
    this.loadOperationsPage();
  }

  loadOperationsPage() {
    /*   this.operations = this.operationService.getOperations(
      this.pageIndex,
      this.pageSize
    ); */
    this.loader = true;
    this.financingService
      .getFinancingList(sessionStorage.getItem('userId')!, this.pageIndex + 1, this.pageSize)
      .pipe(
        tap((res) => {
          this.operations = res.data;
          this.length = res.total;
          this.loader = false;
        }),
        catchError((error) => {
          console.error(error);
          this.loader = false;
          return of(null);
        })
      )
      .subscribe();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex
    this.loadOperationsPage();
  }

  getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'aproved':
        return 'status-aprobado';
      case 'denied':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      case 'completed':
        return 'status-completado';
      case 'signed':
        return 'status-firmado';
      default:
        return '';
    }
  }

  translateState(state: string): string {
    switch (state.toLowerCase()) {
      case 'aproved':
        return 'Aprobado';
      case 'denied':
        return 'Denegado';
      case 'pending':
        return 'Pendiente';
      case 'completed':
        return 'Completado';
      case 'signed':
        return 'Firmado';
      default:
        return state;
    }
  }
  openDetail(element: any) {
    this.router.navigate(['/operacion', element.operationId], {
      state: { operation: element },
    });
  }

  formatOperationId(operationId: number): string {
    return operationId.toString().padStart(6, '0');
  }

  onSearch(event: Event): void {
    const searchText = (event.target as HTMLInputElement).value;
    const commercialId = sessionStorage.getItem('userId')!;
    const size = this.pageSize; // Tamaño de la página actual
    const page = this.pageIndex + 1; // El número de la página actual
  
    if (searchText.length > 2) {
      this.loader = true;
      this.financingService.findByFilter(commercialId, searchText, size, page)
        .pipe(
          tap((res) => {
            this.operations = res.data; // Actualiza las operaciones
            this.length = res.total; // Actualiza el total de resultados
            this.loader = false;
          }),
          catchError((error) => {
            console.error(error);
            this.loader = false;
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.loadOperationsPage(); // Si no hay suficiente texto, carga todas las operaciones
    }
  }
}
