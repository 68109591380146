<div class="operations-container">
  <h1>Mis Operaciones</h1>
  <section class="actions-container">
    <div class="input-type">
      <input
      type="text"
      matInput
      placeholder="Buscar por operación, empresa o CIF/NIF"
      (input)="onSearch($event)"
    />
    <img src="../../../assets/svg/Search.svg" alt="">
    </div>
    <button class="gold-button" routerLink="/simulador/nueva-operacion">
      Nueva operación
    </button>
  </section>
  <section class="table-container" *ngIf="!loader">
    <table
      mat-table
      [dataSource]="operations"
      matSort
      class="mat-table"
    >
      <ng-container matColumnDef="operationNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Operación</th>
        <td mat-cell *matCellDef="let element" class="operation">
          <p class="clickable" (click)="openDetail(element)">
            {{ formatOperationId(element.codeOperation) }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Empresa/Cliente
        </th>
        <td mat-cell *matCellDef="let element"><p class="companyName">{{ element.customer.name }}</p></td>
      </ng-container>

      <ng-container matColumnDef="vatNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CIF/NIF</th>
        <td mat-cell *matCellDef="let element"><p class="vat-number">{{ element.customer.identifier }}</p></td>
      </ng-container>

      <ng-container matColumnDef="operationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Fecha
        </th>
        <td mat-cell *matCellDef="let element"><p class="op-date">{{ element.date | date: "dd/MM/yyyy" }}</p></td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let element">
          <p [ngClass]="getStatusClass(element.state)">
            {{ translateState(element.state) }}
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let element">
          <p class="type">
            {{ element.type === 'LEASING' ? 'L' : element.type }}
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe €</th>
        <td mat-cell *matCellDef="let element"><p class="amount">{{ element.totalAmount| currency:" ":"symbol":"1.0-0":"es" }}</p><p class="total">{{ element.downPayment | currency:" ":"symbol":"1.2-2":"es"}}</p></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      
    </table>
    <mat-paginator #paginator
               class="demo-paginator"
               [length]="length"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               [pageSizeOptions]="pageSizeOptions"
               [showFirstLastButtons]="showFirstLastButtons"
               (page)="handlePageEvent($event)">
</mat-paginator>
  </section>
  <div class="skeleton-container" *ngIf="loader">
    <ngx-skeleton-loader count="{{offset-1}}" appearance="line" [theme]="{ height: '76px' }">
    </ngx-skeleton-loader>
</div>
</div>
